class RecruitersTabs {
  constructor(element) {
    this.container = element
    this.select = element.querySelector('#recruiter-choice')
    this.content = element.querySelector('.recruiters__content')
    this.tabs = element.querySelectorAll('.recruiters-list')
  }

  init = () => {
    this.addEventListeners()
  }

  addEventListeners() {
    this.select.addEventListener('change', this.handleChange)
  }

  handleChange = e => {
    const tab = this.container.querySelector(`ul[aria-labelledby = ${e.target.value}]`)
    if (!this.content.className.includes('recruiters__content--active')) {
      this.content.classList.add('recruiters__content--active')
    }
    this.tabs.forEach(tab => {
      tab.setAttribute('tabindex', 0)
      if (!tab.className.includes('recruiters-list--hidden')) {
        tab.classList.add('recruiters-list--hidden')
      }
    })
    tab.setAttribute('tabindex', 1)
    tab.classList.remove('recruiters-list--hidden')
    this.activeTabId = e.target.value
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const recruitersELments = document.querySelectorAll('.js-recruiters')
  if (!recruitersELments.length) return
  recruitersELments.forEach(element => {
    const recruiters = new RecruitersTabs(element)
    recruiters.init()
  })
})
