import { Date } from 'core-js'

class Banner {
  constructor(banner) {
    this.banner = banner
    this.closeBtn = document.querySelector('.js-banner-close')
    this.isCookie = false
  }

  addCloseEventListener = () => {
    this.closeBtn.addEventListener('click', this.handleClose)
  }

  checkCookieExistance = () => {
    if (document.cookie.split('; ').includes('banner=1')) {
      this.isCookie = true
    } else {
      this.isCookie = false
    }
  }

  handleBannerVisibility = () => {
    if (!this.isCookie) this.showBanner()
  }

  handleClose = e => {
    this.hideBanner()
    this.setCookie()
  }

  init = () => {
    this.addCloseEventListener()
    this.checkCookieExistance()
    this.handleBannerVisibility()
  }

  hideBanner = () => {
    this.banner.classList.add('banner--hidden')
  }

  setCookie = () => {
    let date = new Date()
    date.setDate(date.getDate() + 30)
    document.cookie = `banner=1; SameSite=Lax; Secure; expires=${date.toUTCString()};`
  }

  showBanner = () => {
    this.banner.classList.remove('banner--hidden')
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const banners = document.querySelectorAll('.js-banner')
  if (!banners) return
  banners.forEach(bannerEl => {
    const banner = new Banner(bannerEl)
    banner.init()
  })
})
